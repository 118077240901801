import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import setActiveAddLayerDropdownOperation from 'editor/src/store/app/operation/setActiveAddLayerDropdownOperation';
import isAddLayerDropdownVisible from 'editor/src/store/app/selector/isAddLayerDropdownVisible';
import isAddElementsAllowed from 'editor/src/store/editor/selector/isAddElementsAllowed';
import { useDispatch, useSelector } from 'editor/src/store/hooks';

import AddLayerDropdown from 'editor/src/component/AddLayerDropdown';
import Button from 'editor/src/component/Button';
import IconPlus from 'editor/src/component/Icon/IconPlus';
import { CanShow } from 'editor/src/component/Menu/type';

import styles from './index.module.scss';

export const canShow: CanShow = (state, context) =>
  isAddElementsAllowed(state) && !context.hasSelection && !!state.design?.designData;

function ButtonAddLayer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDropdownVisible = useSelector((state) => isAddLayerDropdownVisible(state, 'top-menu'));

  const onAddLayerClick = () => {
    dispatch(setActiveAddLayerDropdownOperation('top-menu'));
  };

  return (
    <div className={styles.addLayerButtonWrapper}>
      <Button
        secondary
        stopPropagation
        onClick={onAddLayerClick}
        className={cn(styles.button, 'cy-button-add-layer')}
        on={isDropdownVisible}
      >
        <IconPlus className={styles.icon} />
        {t('editor-add-layer')}
      </Button>
      <AddLayerDropdown className={styles.dropDown} isDropdownVisible={isDropdownVisible} menuPosition="bottom-right" />
    </div>
  );
}

export default React.memo(ButtonAddLayer);
